import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import tw from 'twin.macro';
import { ContentSection, Layout, LinkPlus } from '~/components';
import { Frontmatter } from '~/config';
import { extractSlug } from '~/utils';

type LibPagesQueryNode = {
  frontmatter: {
    orderInLib: number | null;
    title: string;
  };
  node: {
    absolutePath: string;
  };
};

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Dev Library',
  orderInLib: 0,
  screenShoot: false,
};

const Page: FC<PageProps> = () => {
  const data = useStaticQuery(graphql`
    {
      allJavascriptFrontmatter(
        filter: { node: { absolutePath: { regex: "/.*/pages/library/.*/" } } }
        sort: { fields: frontmatter___orderInLib }
      ) {
        nodes {
          frontmatter {
            orderInLib
            title
          }
          node {
            absolutePath
          }
        }
      }
    }
  `);

  let nodes = data.allJavascriptFrontmatter.nodes as LibPagesQueryNode[];
  nodes = nodes.filter(
    (item) => !item.node.absolutePath.includes('pages/library/index.tsx'),
  );

  return (
    <Layout>
      <ContentSection flavor="dark">
        <div tw="mt-20 min-h-screen-1/2 flex flex-direction[column]">
          {nodes.map((item) => {
            const path = item.node.absolutePath;
            const file = extractSlug(path);
            return (
              <LinkPlus
                key={file}
                to={file}
                customCss={tw`mb-1 text-white hover:text-brand-yellow no-underline text-sm`}
              >
                {item.frontmatter.title.replace('Library: ', '')}
              </LinkPlus>
            );
          })}
        </div>
      </ContentSection>
    </Layout>
  );
};

export default Page;
